<div class="input">
    <label class="input-label">
        <ng-container *ngIf="label">
            <span class="label-text" [ngClass]="{'label-error': error ? true : false }">{{label | translate}}<span *ngIf="required" class="required"> *</span></span>
        </ng-container>
        <div class="input-text-wrapper">
            <input 
                [type]="type || 'text'" 
                (input)="onChange.emit($event)" 
                [ngClass]="{ 'error': error || status && status==='error' ? true : false, 'success': status && status==='success' ? true : false, 'secure-text-entry': secureTextEntry ? true : false}" 
                [disabled]="disabled" 
                [attr.aria-label]="label | translate"
                [attr.aria-required]="required ? 'true' : 'false'"
                [attr.data-hook]="dataHook || ''"
                [tabindex]="tabindex || ''"
                [placeholder]="placeholder"
                [name]="name || ''"
                class="form-input"
            />
            <span *ngIf="secureTextEntry" (click)="toggleSecureTextEntry($event)" class="secure-text-entry-button">
                <svg *ngIf="type === 'password'" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M9 2.97205C11.2582 2.97205 13.0909 4.80477 13.0909 7.06295C13.0909 7.48023 13.0091 7.88114 12.8945 8.2575L15.3982 10.7611C16.5355 9.75477 17.4355 8.49477 18 7.05477C16.5845 3.47114 13.0909 0.926591 9 0.926591C7.96091 0.926591 6.96273 1.09023 6.02182 1.39295L7.79727 3.16841C8.18182 3.05386 8.58273 2.97205 9 2.97205ZM1.39909 0.239318C1.08 0.558409 1.08 1.07386 1.39909 1.39295L3.01091 3.00477C1.68545 4.06023 0.63 5.45114 0 7.06295C1.41545 10.6548 4.90909 13.1993 9 13.1993C10.2436 13.1993 11.43 12.9539 12.5264 12.5284L14.7518 14.7539C15.0709 15.073 15.5864 15.073 15.9055 14.7539C16.2245 14.4348 16.2245 13.9193 15.9055 13.6002L2.56091 0.239318C2.24182 -0.0797727 1.71818 -0.0797727 1.39909 0.239318ZM9 11.1539C6.74182 11.1539 4.90909 9.32114 4.90909 7.06295C4.90909 6.43295 5.05636 5.83568 5.31 5.31205L6.59455 6.59659C6.57 6.74386 6.54545 6.89932 6.54545 7.06295C6.54545 8.42114 7.64182 9.5175 9 9.5175C9.16364 9.5175 9.31091 9.49295 9.46636 9.46023L10.7509 10.7448C10.2191 11.0066 9.63 11.1539 9 11.1539ZM11.43 6.79295C11.3073 5.6475 10.4073 4.75568 9.27 4.63295L11.43 6.79295Z" fill="#5A5E72"/>
                </svg>
                <svg *ngIf="!type || type == 'text'" width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M9 0C4.90909 0 1.41545 2.54455 0 6.13636C1.41545 9.72818 4.90909 12.2727 9 12.2727C13.0909 12.2727 16.5845 9.72818 18 6.13636C16.5845 2.54455 13.0909 0 9 0ZM9 10.2273C6.74182 10.2273 4.90909 8.39455 4.90909 6.13636C4.90909 3.87818 6.74182 2.04545 9 2.04545C11.2582 2.04545 13.0909 3.87818 13.0909 6.13636C13.0909 8.39455 11.2582 10.2273 9 10.2273ZM9 3.68182C7.64182 3.68182 6.54545 4.77818 6.54545 6.13636C6.54545 7.49455 7.64182 8.59091 9 8.59091C10.3582 8.59091 11.4545 7.49455 11.4545 6.13636C11.4545 4.77818 10.3582 3.68182 9 3.68182Z" fill="#5A5E72"/>
                </svg>
            </span>
        </div>
    </label>
    <div *ngIf="error" class="error-message">
        <span>{{error}}</span>
    </div>
</div>
