<mxc-paywall
  [user]="this.currentUser"
></mxc-paywall>
<mxc-session-timeout
  data-hook="mxc-session-timeout"
  [user]="this.currentUser"
></mxc-session-timeout>
<header class="container-fluid montserrat">
  <div class="row align-items-center" id="header">
    <div class="col d-flex flex-row justify-content-between">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <div *ngIf="mobile">
          <span>
            <button
              id="header-menu-trigger"
              (click)="toggleMenu()"
              class="btn btn-icon header-btn-icon pointer"
              [ngClass]="{ active: isMenuActive }"
              data-hook="header-menu-trigger"
            >
              <fa-icon icon="bars" title="Navigation Menu" class="nav-menu-icon"></fa-icon>
            </button>
          </span>
        </div>
        <div class="ml-2 ml-md-0 mr-2 mr-lg-5" *ngIf="!showSearchOnMobile" [@fadeInAnimation]>
          <a title="Navigate to Homepage" routerLink="/">
            <img
              id="header-logo"
              data-hook="header-logo"
              class="img-fluid"
              alt="MAX/Center Logo"
              src="{{ navbarLogo }}"
            />
          </a>
        </div>
        <div class="align-items-center d-md-flex" *ngIf="!mobile">
          <span *ngFor="let link of navLinks" class="d-inline-block top-nav-links">
            <span (click)="goToDeepLinkApp(link)" class="d-inline-block montserrat top-nav-link" [title]="link.navDisplayName">{{ link.navDisplayName }}</span>
          </span>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end ml-2 ml-sm-0">
        <div class="d-inline-flex mr-3 mr-lg-5">
          <span class="my-auto mr-1" *ngIf="!mobile || (mobile && showSearchOnMobile)" [@fadeInAnimation]>
            <form [formGroup]="searchForm" (ngSubmit)="submitSearch()" autocomplete="off">
              <div class="form-group mr-2 my-auto">
                <fa-icon class="position-absolute search-icon" icon="search" title="Search Trigger"></fa-icon>
                <input
                  data-hook="global-search-input"
                  formControlName="term"
                  name="search"
                  id=global-search-input
                  class="form-control search-input"
                  placeholder="Search MAX/Center"
                />
                <fa-icon (click)="toggleMobileSearch()" class="mobile-search-close-icon" icon="times" title="Close Search"></fa-icon>
              </div>
            </form>
          </span>
          <span *ngIf="mobile && !showSearchOnMobile" class="d-flex align-items-center">
            <fa-icon (click)="toggleMobileSearch(true)" class="mobile-search-open-icon" icon="search" title="Open Search"></fa-icon>
          </span>
        </div>
        <div class="mr-2">
          <img
            *ngIf="currentUser.photoUrl && currentUser.photoUrl !== '0'; else fallback"
            class="nav-img rounded-circle round-shadow"
            data-hook="header-profile-image"
            src="{{ this.currentUser.id | photoUrl: 'profilePhoto':{ staticTimestamp: true } }}"
            (click)="toggleProfile(true)"
          />
          <ng-template #fallback>
            <div
              class="nav-img rounded-circle round-shadow default-user-initials-wrapper d-flex justify-content-center align-items-center"
              data-hook="header-profile-image"
              (click)="toggleProfile(true)"
            >
              <span class="default-user-initials text-white">{{ userInitials(currentUser) }}</span>
            </div>
          </ng-template>
        </div>
        <div class="my-auto flex-row d-flex">
          <div class="montserrat pointer ml-2 shrink-hide profile-menu-text" (click)="toggleProfile(true)">
            Hello {{ currentUser.firstName }}!
          </div>
          <div (click)="toggleProfile(true)" data-hook="header-down-caret" class="shrink-hide ml-3 pointer profile-menu-text">
            <fa-icon icon="caret-down" class="top-nav-caret-down-icon"></fa-icon>
          </div>
          <span *ngIf="profileOpen" (clickOutside)="toggleProfile()" [@flyOutAnimationRight] [delayClickOutsideInit]="true" data-hook="profile-menu" class="profile-menu" [ngClass]="{ 'high-z': profileOpen }">
            <div class="d-none d-md-block text-right mt-4 mr-4">
              <fa-icon [icon]="closeIcon" (click)="profileOpen = !profileOpen"></fa-icon>
            </div>
            <div class="text-center mt-3">
              <div class="d-block d-md-none w-100 text-left pl-3">
                <fa-icon (click)="toggleProfile()" class="mobile-profile-close-icon" icon="times" title="Close Search"></fa-icon>
              </div>
              <div class="header-profile-image-wrapper">
                <a routerLink="/roster/user/{{ this.currentUser.id }}">
                  <img
                    *ngIf="currentUser.photoUrl && currentUser.photoUrl !== '0'; else fallback"
                    class="header-profile-image rounded-circle round-shadow"
                    data-hook="profile-menu-image"
                    src="{{ this.currentUser.id | photoUrl: 'profilePhoto':{ staticTimestamp: true } }}"
                  />
                  <ng-template #fallback>
                    <div
                      class="round-shadow rounded-circle header-profile-image d-flex default-user-initials-wrapper d-flex justify-content-center align-items-center"
                      data-hook="default-profile-menu-image"
                      (click)="toggleProfile(true)"
                    >
                      <span class="default-user-initials">{{ userInitials(currentUser) }}</span>
                    </div>
                  </ng-template>
                </a>
              </div>
            </div>
            <div class="text-center">
              <div class="mt-1 name">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
              <div class="mt-1 email">
                {{ currentUser.remaxEmail }}
              </div>
              <div class="mt-2">
                <a class="update-profile" [href]="getEditUrl()" target="_blank" data-hook="update-profile-link"
                   (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event);"
                  >Update Profile</a
                >
              </div>
            </div>
            <div class="line mx-4 py-2"></div>
            <div class="d-flex flex-column jusify-content-start mt-3">
              <div class="ml-5 account-options">
                <div>
                  <a routerLink="/roster/user/{{ currentUser.id }}/referrals/received" data-hook="referrals-link" (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event);toggleProfile(false)"
                    >Referrals</a
                  >
                </div>
                <div>
                  <a routerLink="/roster/user/{{ this.currentUser.id }}" data-hook="profile-link" (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event); toggleProfile(false)">View my profile</a>
                </div>
                <div>
                  <a routerLink="/roster/user/{{ currentUser.id }}/settings" data-hook="settings-link" (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event); toggleProfile(false)">Settings</a>
                </div>
                <div>
                  <a routerLink="/roster/user/{{ currentUser.id }}/reservations" data-hook="reservations-link" (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event); toggleProfile(false)"
                    >Email Reservations</a
                  >
                </div>
                <div>
                  <a [href]="billPayUrl" target="_blank" data-hook="pay-bill-link" (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event); toggleProfile(false)">Pay Bill</a>
                </div>
                <div *ngIf="showChangeUser && userService.isImpersonating && !userService.isImpersonating()">
                  <a (click)="gtmService.clickEvent({event_name: 'nav', type:'left_nav'}, $event); openChangeUserModal()" data-hook="change-user-menu-item"> Change User </a>
                </div>

                <div *ngIf="userService.isImpersonating && userService.isImpersonating()">
                  <a (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event); revertUser()" data-hook="stop-impersonation"> Stop Impersonating </a>
                </div>
              </div>
            </div>
            <span class="menu-bottom">
              <div class="text-center mt-md-5">
                <p class="logout" (click)="gtmService.clickEvent({event_name: 'nav', type:'header'}, $event); openLogoutModal()" data-hook="logout-menu-item">Log Out</p>
              </div>
              <div class="d-flex flex-row justify-content-center menu-box-color">
                <a href="/user-agreement" (click)="gtmService.clickEvent({event_name: 'nav', type:'footer'}, $event)" target="_blank" data-hook="user-agreement-link">User Agreement</a>
                <p>|</p>
                <a href="/privacy-policy" (click)="gtmService.clickEvent({event_name: 'nav', type:'footer'}, $event)" target="_blank" data-hook="privacy-policy-link">Privacy Policy</a>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</header>
